import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import TakeoutDiningTwoToneIcon from '@mui/icons-material/TakeoutDiningTwoTone';
import MopedTwoToneIcon from '@mui/icons-material/MopedTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import StarRateTwoToneIcon from '@mui/icons-material/StarRateTwoTone';
import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';

const menuItemsAll = [
  {
    heading: '',
    items: [
      {
        name: 'Merchant',
        icon: StorefrontTwoToneIcon,
        link: '/management/profile',
        items: [
          {
            name: 'Overview',
            link: '/dashboard/overview'
          },
          {
            name: 'Menu',
            link: '/dashboard/menu'
          },
          {
            name: 'Categories',
            link: '/dashboard/categories'
          },
          {
            name: 'Orders',
            link: '/dashboard/orders'
          },
          {
            name: 'Working Hours',
            link: '/dashboard/working-hours'
          },
          {
            name: 'Holiday Hours',
            link: '/dashboard/holiday-hours'
          },
          {
            name: 'Feedback',
            link: '/dashboard/feedback'
          },
          {
            name: 'Storefront',
            link: '/dashboard/storefront'
          },
          {
            name: 'Settings',
            link: '/dashboard/settings'
          },
          {
            name: 'Team',
            link: '/dashboard/team'
          },
          {
            name: 'Drivers',
            link: '/dashboard/merchant-drivers'
          },
        ]
      }
    ]
  },
  {
    heading: 'Support',
    items: [
      {
        name: 'Merchants',
        icon: StorefrontTwoToneIcon,
        link: '/support/merchants'
      },
      {
        name: 'Drivers',
        icon: MopedTwoToneIcon,
        link: '/support/drivers'
      },
      {
        name: 'Orders',
        icon: TakeoutDiningTwoToneIcon,
        link: '/support/orders'
      },
      {
        name: 'Users',
        icon: PeopleAltTwoToneIcon,
        link: '/support/users'
      },
      {
        name: 'Effects',
        icon: StarRateTwoToneIcon,
        link: '/support/effects'
      },
      {
        name: 'Categories',
        icon: CategoryTwoToneIcon,
        link: '/support/categories'
      },
      {
        name: 'Promotions',
        icon: SellTwoToneIcon,
        link: '/support/promotions'
      },
      {
        name: 'System Settings',
        icon: SettingsTwoToneIcon,
        link: '/support/settings'
      },
    ]
  }
];

export default menuItemsAll;
