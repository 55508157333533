import { types } from "mobx-state-tree"
import Product from "../../models/product/Product"
import Category from "../../models/category/Category"
import * as views from './merchantProductsStoreViews'
import * as actions from './merchantProductsStoreActions'
import MerchantLogs from "../../models/merchantLogs/merchantLogs"

const MerchantProductsStore = types
  .model('MerchantProductsStore', {
    product: types.maybeNull(types.reference(Product)),
    products: types.maybeNull(types.optional(
      types.array(types.reference(Product)), []
    )),
    allProducts: types.maybeNull(types.number),
    selectedProduct: types.maybeNull(types.reference(Product)),
    selectedCategory: types.maybeNull(types.reference(Category)),
    currentPage: types.maybeNull(types.number),
    currentRow: types.maybeNull(types.number),
    loading: false,
    searchInput: types.maybeNull(types.string, ''),
    orderBy: types.maybeNull(types.string, null),
    statuses: types.optional(
      types.array(types.string), ['ACTIVE', 'DRAFT']
    ),

    merchantLogs: types.optional(
      types.array(MerchantLogs), []
    ),
  })
  .views(views.merchantProductsStoreViews)
  .actions(actions.merchantProductsStoreActions)

export default MerchantProductsStore