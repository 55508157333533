import { types } from "mobx-state-tree"
import Merchant from '../merchant/Merchant'
import * as views from './OrderViews'
import * as actions from './OrderActions'
import Product from "../product/Product"
import User from "../user/User"
import Location from "../location/Location"
import OrderLog from "../orderLog/OrderLog"
import DeliveryTask from "../deliveryTask/DeliveryTask"
import Feedback from "../feedback/Feedback"
import Coupon from "../coupon/coupon"

const Order = types
  .model("Order", {
    id: types.identifierNumber,
    totalAmount: types.maybeNull(types.number),
    tax: types.maybeNull(types.number),
    deliveryFee: types.maybeNull(types.number),
    deliveryType: types.maybeNull(types.string),
    deliveryLocation: types.maybeNull(types.reference(Location)),
    deliveryTasks: types.maybeNull(
      types.array(types.reference(DeliveryTask)), []
    ),
    otherFees: types.maybeNull(types.number),
    subtotal: types.maybeNull(types.number),
    tip: types.maybeNull(types.number),
    promoCode: types.maybeNull(types.string),
    orderDate: types.maybeNull(types.string),
    instructions: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    publicOrderId: types.maybeNull(types.string),
    invoiceLink: types.maybeNull(types.string),
    driverPaid: types.maybeNull(types.boolean),
    merchantPaid: types.maybeNull(types.boolean),
    merchant: types.maybeNull(
      types.reference(Merchant)),
    buyer: types.maybeNull(
      types.reference(User)),
    rating: types.maybeNull(
      types.reference(Feedback)),
    products: types.maybeNull(
      types.array(types.reference(Product)), []
    ),
    logs: types.maybeNull(
      types.array(OrderLog), []
    ),
    coupon: types.maybeNull(
      types.reference(Coupon)
    )
  })
  .views(views.orderViews)
  .actions(actions.orderActions)

export default Order