import { api_get_products_by_merchant_id_paginate } from "../../../queries/products"
import { api_get_orders_by_merchant_id_paginate } from "../../../queries/orders"
import { toJS } from "mobx"

export const merchantOrdersStoreActions = self => ({
    async getMerchantOrdersPaginate(page, row) {
        try {
            self.set('loading', true)
            const res = await api_get_orders_by_merchant_id_paginate(self.token, page, row, self.merchantId, self.searchString, self.status)
            if (res.error) throw res

            if (res.count !== 0) {
            self.set('allOrders', res.data?.count)
            }
            const ids = await self.ordersFactory.addUpdateOrders(res.data.rows)
            self.set('orders', null)
            self.set('orders', ids)
            self.set('loading', false)
            return res.data

        } catch (err) {
            self.set('loading', false)
            console.log(err)
        }
    },
    querrySearch(value) {
        self.set('searchInput', value)
        self.getMerchantOrdersPaginate(self.currentPage, self.currentRow)
      },
    set(key, value) {
        self[key] = value
      }
})