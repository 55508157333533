import { types } from "mobx-state-tree";
import * as actions from './singleNotificationActions';
import * as views from './singleNotificationViews';
import Coupon from "../../models/coupon/coupon";

const SingleNotificationStore = types
  .model('SingleNotificationStore', {
    selectedCoupon: types.maybeNull(
      types.reference(Coupon)
    ),

    title: types.maybeNull(types.string),
    text: types.maybeNull(types.string),
    type: types.optional(types.string, "PROMOTION"),
      
    titleError: types.maybeNull(types.string),
    textError: types.maybeNull(types.string),
    selectedCouponError: types.maybeNull(types.string),

    loading: false,
  })
  .views(views.singleNotificationViews)
  .actions(actions.singleNotificationActions);

export default SingleNotificationStore;


