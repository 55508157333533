import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';

const merchant = {
    heading: '',
    items: [
      {
        name: 'Merchant',
        icon: StorefrontTwoToneIcon,
        link: '/management/profile',
        items: [
          {
            name: 'Overview',
            link: '/dashboard/overview'
          },
          {
            name: 'Menu',
            link: '/dashboard/menu'
          },
          {
            name: 'Categories',
            link: '/dashboard/categories'
          },
          {
            name: 'Orders',
            link: '/dashboard/orders'
          },
          {
            name: 'Working Hours',
            link: '/dashboard/working-hours'
          },
          // {
          //   name: 'Holiday Hours',
          //   link: '/dashboard/holiday-hours'
          // },
          {
            name: 'Feedback',
            link: '/dashboard/feedback'
          },
          {
            name: 'Storefront',
            link: '/dashboard/storefront'
          },
          {
            name: 'Settings',
            link: '/dashboard/settings'
          },
          {
            name: 'Team',
            link: '/dashboard/team'
          },
          {
            name: 'Drivers',
            link: '/dashboard/merchant-drivers'
          },
        ]
      }
    ]
  }


export default merchant;
