import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers';
import { useRoutes } from 'react-router-dom';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Provider } from 'mobx-react'

import { persist } from 'mst-persist'
import localForage from 'localforage'
import Store from './context/root/Store' 

import { ReactNotifications} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import { toJS } from 'mobx';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hotjar from '@hotjar/browser';

const store = Store.create({})

const App = () => {

  const content = useRoutes(routes);
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    persist(`budz-cms-${process.env.REACT_APP_API_URL}-v2.41`, store, {
      storage: localForage,
      jsonify: false,
    }).then(res => {
      setLoading(false)
      store.set('hydrated', true)
      if (store.authStore.token) {
        store.initializeBudz()
      }
    })

    Hotjar.init(3883262, 6, {
      debug: false
    });

  }, [])

  

  return (
      <Provider store={store}>
        { store.hydrated === true &&
            <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ReactNotifications />
              <ToastContainer />
              <CssBaseline />
              {content}
            </LocalizationProvider>
          </ThemeProvider>
        }
      </Provider>
  );
}
export default App;
